import React, {useEffect, useCallback} from 'react'
import { createCheckoutSession } from '../stripe/createCheckoutSession'
import { useLocation } from "react-router-dom";

const Redirect = () => {
    const { state } = useLocation();
    const { uid } = state

    const redirectToCheckoutPage = useCallback(async () => {
        createCheckoutSession(uid)
    }, [uid])

    useEffect(() => {
        if(uid){
            console.log('Checkout proxy')
            redirectToCheckoutPage(uid)
        }
    }, [redirectToCheckoutPage])

    return (
        <div style={{ width: '100%', position: 'relative', backgroundColor: '#fff', height: 500, margin: 'auto', padding: 20,  marginTop: 20 }}>
            <div style={{ width: 400, position: 'absolute', top: '30%', left: '50%', transform: 'translate(-50%,-50%)'}}>
                <div style={{textAlign: 'center'}}>
                    Redirecting to the checkout page. Please don't press back or refresh the page...
                </div>
            </div>
        </div>
    )
}

export default Redirect
