import React, {useState, useEffect, useRef} from 'react'
import logo from "../assets/img/Diveo-Logo-Black.png";
import { logoutUser} from '../services/auth'
import { useIsAuthenticated } from '../hooks/useIsAuthenticated';
import '../css/input.css'
import { useNavigate } from "react-router-dom";

const MenuBar = () => {
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = useState(false)
    const [user, setUser] = useState({name: '', email: ''})
    const authUser = useIsAuthenticated()

    const menuRef = useRef()

    // useEffect(() => {
    //     const handler = (e) => {
    //         if (menuRef.current && !menuRef.current.contains(e.target)) {
    //             setShowMenu(false);
    //         }
    //     };
    
    //     window.addEventListener("click", handler);
    //     return () => {
    //       window.removeEventListener("click", handler);
    //     };
    // });

    useEffect(() => {
        if(authUser){
            console.log(authUser)
            setUser({name: authUser.displayName, email: authUser.email})
        }
    }, [authUser])

    const handleClick = (e) => {
        console.log('Show Menu: ', showMenu)
        setShowMenu(!showMenu);
    };

    const openProjects = () => {
        navigate("/projects");
    }

    return (
        <div style={{height: '8vh', width: '100%', backgroundColor: '#ffffff', padding: 5, borderBottom: '1px solid #ddd'}}>
            <div style={{float: 'left'}}>
                <a href="/home"><img src={logo} style={{height: '2.5rem', marginLeft: 20}} alt=""></img></a>
            </div>

            {
                authUser && (
                    <div style={{float: 'right', position: 'relative'}}>
                
                        <div style={{display: 'flex', width: 280, justifyContent: 'right', alignItems: 'right'}}>
                            <div style={{padding: 7, fontSize: 18, borderRadius: 20, height: 40, width: 40, color: '#fff', textAlign: 'center', backgroundColor: '#0A7E71', marginRight: 20, cursor: 'pointer'}} onClick={handleClick}>{user.name.charAt(0).toUpperCase()}</div>
                            
                        </div>
                    
                        {
                            showMenu &&
                                <div ref={menuRef} style={{position: 'absolute', right: 10, top: 45, width: 280, borderRadius: 10, border: "1px solid #ddd", marginTop: 10, backgroundColor: '#fff', paddingTop: 20, paddingBottom: 10}}>
                                    <div class='row'>
                                        <div class='col-2 menubar-profile-logo' >
                                            <div style={{padding: 7, fontSize: 18, borderRadius: 20, height: 40, width: 40, color: '#fff', textAlign: 'center', backgroundColor: '#0A7E71', marginRight: 20, cursor: 'pointer'}} onClick={handleClick}>{user.name.charAt(0).toUpperCase()}</div>
                                        </div>
                                        <div class='col-10 menubar-profile-details'>
                                            <div>{user.name}</div>
                                            <div class='menubar-profile-email'>{user.email}</div>
                                        </div>
                                    </div>
                                    <hr style={{border: '1px solid #ccc'}}/>
                                    <div class='menubar-item' onClick={openProjects}><i class="bi bi-play-btn"></i> <span style={{marginLeft: 5}}>My Videos</span></div>
                                    <div class='menubar-item' onClick={logoutUser}><i class="bi bi-box-arrow-left"></i> <span style={{marginLeft: 5}}>Signout</span></div>
                                </div>
                        }
                    </div>
                )
            }
            
        </div>
    )
}

export default MenuBar
