import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "authReducer",
  initialState: {
    loading: true,
    isLoggingIn: false,
    isLoggingOut: false,
    isVerifying: false,
    loginError: false,
    logoutError: false,
    isAuthenticated: false,
    user: {},
  },
  reducers: {
    requestLogin(state, action) {
      state.isLoggingIn = true;
      state.loginError = false;
    },
    receiveLogin(state, action) {
      state.isLoggingIn = false;
      state.isAuthenticated = true;
      state.loading = false;
      state.user = action.payload.user;
    },
    loginError(state, action) {
      state.isLoggingIn = false;
      state.isAuthenticated = false;
      state.loginError = true;
    },
    requestLogout(state, action) {
      state.isLoggingOut = true;
      state.logoutError = false;
    },
    receiveLogout(state, action) {
      state.isLoggingOut = false;
      state.loading = false;
      state.isAuthenticated = false;
      state.user = {};
    },
    logoutError(state, action) {
      state.isLoggingOut = false;
      state.logoutError = true;
    },
    verifyRequest(state, action) {
      state.isVerifying = true;
      state.verifyingError = false;
    },
    verifySuccess(state, action) {
      state.isVerifying = false;
    },
  },
});

const { actions, reducer } = authSlice;

export const {
  requestLogin,
  receiveLogin,
  loginError,
  requestLogout,
  receiveLogout,
  logoutError,
  verifyRequest,
  verifySuccess,
} = actions;

export default reducer;
