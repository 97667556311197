import React, {useState, useEffect, useCallback} from 'react'
import { useSelector } from 'react-redux'
import { getExecutionStatus } from '../services/render'
import { useLocation } from "react-router-dom";
import axios from 'axios'
import { updateProjectDetails } from '../services/firebaseStore';


const ExportView = () => {
    const { state } = useLocation();
    const { data } = state

    const [isProcessing, setIsProcessing] = useState(false)
    const [isFailed, setIsFailed] = useState(false)
    const [progress, setProgress] = useState(1)
    const [downloadLink, setDownloadLink] = useState(null)
    const [videoDims, setVideoDims] = useState()

    const aspectRatio = useSelector(
        (state) => state.storyReducer.settings.aspectRatio,
    )

    useEffect(() => {
       // Search for download link in redux
       
    }, [])

    const sendDataForProcessing = useCallback(async () => {
        console.log(`Sending data: ${data} for processing `)
        // API Gatewat Endpoint - https://3cl2zk6qqg.execute-api.ap-northeast-1.amazonaws.com/dev/export
        try {
            let response = await axios.post(
              `https://3cl2zk6qqg.execute-api.ap-northeast-1.amazonaws.com/dev/export`, 
              data,
              {
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                },
              },
            )
            let result = response.data 
            console.log('Response: ', result)
            setIsProcessing(true)
            
        } catch (error) {
            console.log('Error Occurred')
            console.log(error)
            return false
        }
    }, [data])

    useEffect(() => {
        console.log(data)
        if(data){
            // Send data to start processing video
           sendDataForProcessing()
        }
    }, [sendDataForProcessing])


   
    useEffect(() => {
        console.log('Job Id: ', data.jobId)
        const jobId = data.jobId
        const projectId = data.projectId
        
        if(isProcessing && jobId){
            // TODO: Caculate the correct eta 
            const eta = 18 * data.totalDuration ?? 900;
            const startTime = new Date().getTime()
            calculateVideoDims()
            function getExecutionStatusRes() {
                const currentTime = new Date().getTime()
                const diff = (currentTime - startTime)/1000
                const percentageProgress = Math.min(Math.round(diff/eta * 100), 99)
                if(percentageProgress < 100){
                    setProgress(percentageProgress)
                    console.log('Time since: ', diff)
                }else{
                    return () => {
                        clearInterval(interval);
                    }
                }
                
                getExecutionStatus(jobId).then((res) => {
                    console.log('Res: ', res)
                    console.log('')
                    if(res) {
                        if(res.status == 'SUCCEEDED'){
                            console.log('STATUS: SUCCEEDED' )
                            // get download link from response
                            const resOutput = JSON.parse(res.output)
                            const resOutputData = JSON.parse(resOutput.body)
                            const downloadLink = resOutputData.downloadLink;
                            console.log('Res Output Data: ', resOutputData)
                            setDownloadLink(downloadLink)
                            updateProjectDetails(projectId, 'SUCCESS', downloadLink)
                            setIsProcessing(false)
                            setProgress(100)
                            return () => {
                                clearInterval(interval);
                            }
                        }else if(res.status == 'FAILED'){
                            // Log error that processing failed
                            setIsProcessing(false)
                            setIsFailed(true)
                            return () => {
                                clearInterval(interval);
                            }
                        }
                    }
                }).catch((err) => {
                    console.log('STATUS: FAILED' )
                    console.log('Error: ', err)
                    console.log('Error Occured. Please contact the app owner')
                    setIsFailed(true)
                    setIsProcessing(null)
                })
                

            }
            getExecutionStatusRes()
            const interval = setInterval(() => getExecutionStatusRes(), 10000)
            return () => {
                clearInterval(interval);
            }
        }else{
            setProgress(100)
        }
      
        
    }, [isProcessing])

    const calculateVideoDims = () => {
        if(aspectRatio == '16/9'){
            setVideoDims({height: 450, width: 800})
        }else if(aspectRatio == '1/1'){
            setVideoDims({height: 450, width: 450})
        }else if(aspectRatio == '9/16'){
            setVideoDims({height: 450, width: 253})
        }
    }


    return (
        <div style={{ width: 900, position: 'relative', backgroundColor: '#fff', height: 700, margin: 'auto', padding: 20,  marginTop: 20 }}>
        
            <div style={{ width: 850, position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%,-50%)',}}>
                {
                    isProcessing && !isFailed ? 
                        <div style={{textAlign: 'center'}}>
                            <div style={{ display: 'inline-block', marginLeft: 25, fontSize: 60, fontWeight: 600}}>{progress}%</div>
                            <h5 style={{width: '60%', margin: 'auto', marginTop: 20}}>Rendering your video</h5>
                            <div style={{position: 'relative', overflow: 'hidden', width: '80%',  margin: 'auto', height: 16, backgroundColor: '#eee', borderRadius: 5, marginTop: 20}}>
                                <div style={{
                                    position: 'absolute',
                                    height: '100%',
                                    width: `${progress}%`,
                                    backgroundColor: '#584efd',    
                                }}>
                                </div>
                            </div>
                            <div style={{width: 300, margin: 'auto', marginTop: 30, fontSize: 18}}>Please be patient as this may take several minutes. </div>
                        </div> : <div style={{width: '60%', margin: 'auto', marginTop: 30, fontSize: 21, fontStyle: 'italics'}}>
                                Preparing to render the video...
                            </div>

                }
                { isFailed && 
                    <div style={{textAlign: 'center'}}>
                        <h3>Video Generation Failed!</h3>
                        <p>Please contact the app owner for more info. </p>
                    </div> 
                }
                {downloadLink && 
                    <div style={{textAlign: 'center'}}>
                        <div style={{fontSize: 28, color: '#000', marginBottom: 20}}>Your video is ready! </div> 
                        <video
                            width={videoDims.width}
                            height={videoDims.height}
                            controls 
                            src={downloadLink}
                        >
                                   
                        </video>
                    </div>
                    
                }
            </div>
           
            
            <br />
            

        
        
           
        </div>
    )
}

export default ExportView
