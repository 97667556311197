import React, { useState, useRef, useEffect } from "react";
import { fabric } from "fabric";
import { useDispatch, useSelector } from 'react-redux'
import { updateSelectedObjId, setSceneInfo, setCaptionStyle, updateTimeline } from '../redux/storySlice'
import { addOrReplaceVideo, renderFactory, clearCanvasAsync } from "../utils/renderUtils";
import {
    getPreviewContainerDimensions,
} from "../constants/canvasSettings";
import { playerModes, composeScene, updateObjectCoords, updateObjectScaleValue } from "../utils/sceneUtils"; 
import { sendActionForProcessing } from "../utils/actionManager";
import SceneTracker from "./SceneTracker";
import { createSceneBounds, delay } from "../utils/createUtils";
import { useIsAuthenticated } from '../hooks/useIsAuthenticated';

import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';

const audioContext = new (window.AudioContext || window.webkitAudioContext)();


const Player = () => {
    const dispatch = useDispatch()

    const authUser = useIsAuthenticated()

    const canvasRef = useRef();
    const fabricRef = useRef();
    const clipPathRef = useRef();
  
    const videoRef = useRef({});
    const textRef = useRef();

    const playRef = useRef(false)
    const [playing, setPlaying] = useState(false)

    const wordTimestamps = useRef([])
    const wordsIndexTracker = useRef()
    const wordIndexInString = useRef()

    const currentSceneRef = useRef(1)

    const currentTimeRef = useRef(0)
    const renderInitTime = useRef()

    const audioRef = useRef()
    const audioBuffer = useRef()
    const audioSourceRef = useRef()

    const [containerParams, setContainerParams] = useState();
    const [scenes, setScenes] = useState([]);

    const [currentSceneThumbnail, setCurrentSceneThumbnail] = useState()
    const [currentObjectPosition, setCurrentObjectPosition] = useState(null)
    const [currentObjectScale, setCurrentObjectScale] = useState(null)


    const timeline = useSelector(
        (state) => state.storyReducer.timelineInfo
    )

    const audioInfo = useSelector(
        (state) => state.storyReducer.audioInfo
    )

    const captionStyle = useSelector(
        (state) => state.storyReducer.captionStyle
    )

    const sceneInfoArray = useSelector(
        (state) => state.storyReducer.sceneInfoArray
    )

    const activeSceneIndex = useSelector(
        (state) => state.storyReducer.activeSceneIndex
    )

    const storySettings = useSelector(
        (state) => state.storyReducer.settings
    )

    const actionLog = useSelector(
        (state) => state.storyReducer.actionLog
    )

    useEffect(() => {
        console.log('AUDIO INFO: ', audioInfo)
        
        if(!containerParams){
            const containerSettings = getPreviewContainerDimensions(storySettings.aspectRatio);
            setContainerParams(containerSettings);
            console.log("VIDOE REFS: ", videoRef);
            if(!scenes.length){
              getSceneArrayFromInfo()
            }

            // if(!captionStyle){
            //   var captionOptions = {
            //       animWord: {
            //         fill: "#000",
            //       },
            //       borderRadius: 5,
            //       fill: "#ccc",
            //       backgroundColor: "#eee",
            //       fontFamily: "Arial",
            //       fontSize: 24,
            //       fontWeight: 700,
            //       padding: 4,
            //       top: 300,
            //       left: 400,
            //       originX: 'center'
            //   };
            //   dispatch(setCaptionStyle({captionStyle: captionOptions}))
            // }
        }
    }, []);

    useEffect(() => {
        // Only updated when there is an existing video story
        const updateDataAfterVoiceChange = async () => {
            console.log('AUDIO INFO UPDATED: ', audioInfo)
            var sceneArray = getSceneArrayFromInfo();
            setScenes(sceneArray);
            console.log('UPDATED SCENE ARRAY: ', sceneArray)

            // Update timeline start and end bounds too;
            if(timeline.length){
                var newTimelineArray = []
                for(let i=0; i < timeline.length; i++){
                    const obj = timeline[i];
                    const sceneId = obj.sceneId;

                    const sceneInfo = sceneArray[sceneId]

                    // TODO: To be changed later
                    const startTime = sceneInfo.start;
                    const endTime = sceneInfo.end;

                    newTimelineArray.push({...obj, startTime: startTime, endTime: endTime})
                }

                wordTimestamps.current = []
                console.log('UPDATED TIMELINE: ', newTimelineArray)
                dispatch(updateTimeline({ timelineInfo: newTimelineArray }));
            }

            // loadAudio()
            await delay(1000)
            setCurrentScene(sceneArray[activeSceneIndex])
        }

        if(scenes.length && !sceneInfoArray.length){
            updateDataAfterVoiceChange()
        }

        if(audioInfo){
            loadAudio()
        }
    }, [audioInfo])

    useEffect(() => {
        const containerSettings = getPreviewContainerDimensions(storySettings.aspectRatio);
        setContainerParams(containerSettings);
        wordTimestamps.current = []

        async function clearAndLoadCanvas(){
            const canvas = fabricRef.current
            if(canvas){
                canvas.clear()
                await clearCanvasAsync(canvas)

                canvas.setBackgroundColor("#EBECF0");
                
                canvas.renderAll();

                var clipPath = new fabric.Rect({
                    id: 'clip-path',
                    width: containerSettings.viewWidth,
                    height: containerSettings.viewHeight,
                    top: containerSettings.viewY,
                    left: containerSettings.viewX,
                    fill: "#ddd",
                    absolutePositioned: true,
                    selectable: false,
                });
                clipPathRef.current = clipPath;

                canvas.add(clipPath)
                canvas.moveTo(clipPath, 0)
                canvas.requestRenderAll()
    
                // console.log('Setting up stage after aspect change: ', fabricRef.current)
                setCurrentScene(scenes[activeSceneIndex])
                canvas.renderAll();
            }
        }
        if(fabricRef.current){
            clearAndLoadCanvas()
        }

    }, [storySettings.aspectRatio])

    useEffect(() => {
        if (!canvasRef.current) return;
        if (fabricRef.current) return;
        if (containerParams && scenes) {
            console.log('Setting Up Stage')
            const canvas = new fabric.Canvas('canvas', {
                width: containerParams.width,
                height: containerParams.height,
                preserveObjectStacking: true,
                backgroundColor: "#EBECF0",
            });
    
            canvas.on('object:resizing', resizeHandler)
            canvas.on('object:moving', dragHandler)
            canvas.on('object:scaling', scaleHandler)
            canvas.on('selection:created', selectionHandler)
            canvas.on('selection:updated', selectionHandler)
            canvas.on('selection:cleared', deselectionHandler)
            // canvas.on('mouse:wheel', mouseWheelHandler)
            
            fabric.Object.prototype.transparentCorners = false;
            fabric.Object.prototype.cornerColor = 'white';
            fabric.Object.prototype.cornerSize = 10;
            fabric.Object.prototype.borderColor = '#7CB9E8';
            fabric.Object.prototype.cornerStyle = 'circle';
            fabric.Object.prototype.setControlsVisibility({ mtr: false });
    
            var clipPath = new fabric.Rect({
                id: 'clip-path',
                width: containerParams.viewWidth,
                height: containerParams.viewHeight,
                top: containerParams.viewY,
                left: containerParams.viewX,
                fill: "#ddd",
                absolutePositioned: true,
                selectable: false,
            });
            clipPathRef.current = clipPath;
            canvas.add(clipPath)
            canvas.moveTo(clipPath, 0)
            canvas.requestRenderAll()

            fabricRef.current = canvas;

            console.log('CANVAS OBJS 111: ', fabricRef.current.getObjects())
    
            // Set total scene count
            console.log('Setting up stage: ', fabricRef.current)
            setCurrentScene(scenes[activeSceneIndex])
        }
    
    }, [containerParams, scenes]);


    useEffect(() => {
        console.log('CAPTION STYLE CHANGED: ', captionStyle)
        if(textRef.current){
            updateCaptionStyle()
        }
    }, [captionStyle])

    useEffect(() => {
        if(fabricRef.current){
            if(actionLog[0]?.status == 0){
                console.log('ACTION LOG: ', actionLog)
                processAndTriggerAction(actionLog)
               
            }else if(actionLog[0]?.status == 1){
                setCurrentScene(scenes[activeSceneIndex])
            }
        }
    }, [actionLog])

    useEffect(() => {
        console.log('VIDEO REFS UPDATED: ', videoRef.current)
    }, [videoRef.current])

    useEffect(() => {
        console.log('AUTH: =====> ', authUser)
        if(scenes && fabricRef.current){
            setCurrentScene(scenes[activeSceneIndex])
            playRef.current = false
        }
    }, [activeSceneIndex])

    useEffect(() => {
        if(currentSceneThumbnail){
            // dispatch(updateThumbnailForScene({sceneId: activeSceneIndex, thumbnail: currentSceneThumbnail}))
        }
    }, [currentSceneThumbnail])

    useEffect(() => {
        if(currentObjectPosition){
            console.log('SELECTED OBJ POS: ', currentObjectPosition)
            var canvas = fabricRef.current
            var activeObj = canvas.getActiveObject()
            if(activeObj){
                console.log('ACTIVE OBJ: ', activeObj)
                updateObjectCoords(activeObj.id, timeline, currentObjectPosition )
                   
            }
        }
    }, [currentObjectPosition])

    useEffect(() => {
        if(currentObjectScale){
            var canvas = fabricRef.current
            var activeObj = canvas.getActiveObject()
            if(activeObj){
                console.log('ACTIVE OBJ: ', activeObj)
                updateObjectScaleValue(activeObj.id, timeline, currentObjectScale)
            }
          
        }
     }, [currentObjectScale])

 

    const setCurrentScene = (scene) => {
        console.log('Setting New Scene: ', scene)
        console.log('TIMELINE::', timeline)
        console.log('VIDEO REFS: ', videoRef.current)
        console.log('ACTION LOG: ', actionLog)
        currentSceneRef.current = scene;
        console.log('SCENE: ', scene)
        var sceneInfo = currentSceneRef.current

        console.log('CANVAS OBJS: ', fabricRef.current.getObjects())
        composeScene(
            currentSceneRef.current,
            audioInfo,
            timeline,
            fabricRef.current,
            containerParams,
            clipPathRef,
            videoRef,
            textRef,
            captionStyle,
            wordTimestamps,
            wordsIndexTracker,
            wordIndexInString,
            playerModes.PLAYER
        )
    }

    const getSceneArrayFromInfo = () => {
        var sceneBoundsArray;
        if(!sceneInfoArray.length){
            console.log('UPDATING SCENES: ', audioInfo.sentenceRangeMap)
       
            sceneBoundsArray = createSceneBounds(audioInfo.sentenceRangeMap, audioInfo.duration)
            setScenes(sceneBoundsArray)
            dispatch(setSceneInfo({sceneInfo: sceneBoundsArray}))
        }
        else{
            sceneBoundsArray = sceneInfoArray
            setScenes(sceneInfoArray)
        }   
        return sceneBoundsArray;
    }


    const scaleHandler = (event) => {
        let newObjectScale = {
            'x': event.target.scaleX,
            'y': event.target.scaleY
        }
        console.log('Scale: ', newObjectScale)
        setCurrentObjectScale(newObjectScale)
        
    }

    const dragHandler = (event) => {
        console.log('Active Object: ', fabricRef.current.getActiveObject())
        console.log('Drag Event: ', fabricRef.current.getActiveObject()?.type == 'image')
        if(fabricRef.current.getActiveObject()){
            let newCoordinates = {
                'x': event.target.left,
                'y': event.target.top
            }
            setCurrentObjectPosition(newCoordinates)
        
        }
    }

    const resizeHandler = (event) => {
        console.log('Active Object: ', fabricRef.current.getActiveObject())

        if(fabricRef.current.getActiveObject()){
            console.log('Text Box', event.target)
        }
    }

    const selectionHandler = (event) => {
        console.log('SELECTED OBJ: ', event.target)
        var activeObj = fabricRef.current.getActiveObject()
        console.log('OBJECTS CANVAS: ', fabricRef.current.getObjects())
        console.log("INDEX OF OBJECT: ", fabricRef.current.getObjects()?.indexOf(activeObj));
        console.log('Active Object: ', activeObj)
        if(activeObj){
            dispatch(
                updateSelectedObjId({objId: activeObj.id})
            )
        } 
    }

    const deselectionHandler = (event) => {
        console.log('No object selected')
        dispatch(
            updateSelectedObjId({objId: null})
        )
    }

    const updateCaptionStyle = () => {
        if(fabricRef.current && textRef.current){
            console.log('UPDATING CAPTION STYLE: ', captionStyle)
            var text = textRef.current.text
            
            console.log('CANVAS WIDTH: ', fabricRef.current.getWidth())

            textRef.current.set({...captionStyle, text: text, left: fabricRef.current.getWidth() / 2,  originX: "center"})
           
            console.log('text ref: ', textRef.current)
            fabricRef.current.renderAll()
        }
        
    }

    const loadAudio = () => {
        console.log('LOADING AUDIO')
        return new Promise((res, rej) => {
            const request = new XMLHttpRequest();
            request.open(
                "GET",
                audioInfo.url,
                true
            );
            request.responseType = "arraybuffer";
    
            request.onload = function () {
                console.log("Request Response: ", request.response);
                audioContext.decodeAudioData(request.response, function (buffer) {
                    audioBuffer.current = buffer;
                    createAudioSource()
                    res('Loaded Audio Successfully!')
                });
            };
            request.send();
        });
    }

    const createAudioSource = async () => {
        if(!audioBuffer.current){
            await loadAudio()
        }
        let audioSource = audioContext.createBufferSource();
        audioSource.buffer = audioBuffer.current;
        audioSource.connect(audioContext.destination);

        audioSourceRef.current = audioSource
        return audioSource
    }
    
    const createVideoElement = (videoInfo) => {
        var video = document.createElement("video");
        video.crossorigin = "anonymous";
        video.muted = true;
        video.height = videoInfo.height;
        video.width = videoInfo.width;
        video.src = videoInfo.url;

        return video;
    };

    function inRange(x, min, max) {
        return (x - min) * (x - max) <= 0;
    }

    const processAndTriggerAction = (actionLog) => {
        if(actionLog[0].status == 0){
            const timelineInfo = [...timeline]
            sendActionForProcessing(activeSceneIndex, actionLog[0], timelineInfo, containerParams, fabricRef.current, videoRef, clipPathRef)
        }
    }


    const startRenderAnimation = async () => {

        console.log('PLAY REF:: ', playing)
        if(!playing){
            setPlaying(true)
            renderInitTime.current = performance.now() / 1000; // Get the start time in seconds
    
            console.log('Starting Render')
            const sceneInfo = currentSceneRef.current
            console.log('Scene Info: ', sceneInfo)
    
            // const audioSource = await createAudioSource()
    
           
            const audioSource = await createAudioSource()
            audioSource.start(0, sceneInfo.start);

            if(wordTimestamps){
                const startTime = sceneInfo.start;

                wordsIndexTracker.current = wordTimestamps.current.findIndex(
                    (obj) => obj.start == startTime
                );
            
                if (wordIndexInString) {
                    wordIndexInString.current = 0;
                }
            }

            // animationFrameId = requestAnimationFrame(render);
            var renderer = renderFactory(  
                currentSceneRef.current,
                renderInitTime,
                timeline,
                fabricRef,
                clipPathRef,
                videoRef,
                textRef,
                captionStyle,
                setPlaying,
                audioSourceRef,
                wordTimestamps,
                wordsIndexTracker,
                wordIndexInString,
                null,
                playerModes.PLAYER
            )
            renderer.start()
        }
       
    }
    
    return (
        <div style={{width: '100%', height: '90vh', backgroundColor: '#EBECF0', paddingInline: 20}}>
            {
                containerParams && 
                    <>
                        <div style={{height: containerParams.height, width: containerParams.width, marginLeft: '0 auto'}}>
                            <canvas id="canvas" ref={canvasRef} style={{}}>

                            </canvas>
                        
                            <audio ref={audioRef}></audio>
                        </div>
                        <div
                            style={{
                                width: containerParams.width,
                                textAlign: 'center',
                                cursor: "pointer",
                                
                            }}
                        >
                            <span onClick={startRenderAnimation}>
                                { 
                                    playing ? 
                                        <PauseRoundedIcon style={{ fontSize: 40}} />
                                        : <PlayArrowRoundedIcon style={{ fontSize: 40}}/>
                                }
                            </span>
                            <span>{playRef.current}</span>
                               
                                 
                        </div>
                        <SceneTracker />
                    </>
            }

            
        </div>
    )
}

export default Player
