import React, {useState, useEffect} from 'react'

import { Outlet, Navigate } from 'react-router-dom'
import { useIsAuthenticated } from '../hooks/useIsAuthenticated';
import { useSelector } from 'react-redux'

const PrivateRoute = ({children}) => {

    const authState = useSelector(
        (state) => state.authReducer
    )

    console.log('AUTH STATE: ', authState)
    return !authState.loading && (authState.isAuthenticated ? 
         children :  <Navigate to="/login" replace />)


}

export default PrivateRoute