import { useState, useEffect } from 'react';
import Webfont from 'webfontloader';


export function useIsFontListLoaded() {
    const [isFontListLoaded, setIsFontListLoaded] = useState(false)

    useEffect(() => {
        var startTime;
        Webfont.load({
            google: {
                families: ['Montserrat:400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic',
                    'Roboto:400,400italic,500,500italic,700,700italic,900,900italic',
                    'Roboto Mono:400,400italic,500,500italic,600,600italic,700,700italic',
                    'Lato:400,400italic,700,700italic,900,900italic',
                    'Courier Prime:400italic,700italic',
                    'Raleway:400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic',
                    'Oswald:400,400italic,500,500italic,600,600italic,700,700italic',
                    'Merriweather:400,400italic,700,700italic,900,900italic',
                    'PT Sans:400,400italic,700,700italic'
                ],
            },
            timeout: 2000,
            loading: function() {
                console.log('FONTS LOADING!!!')
                console.time('LOAD_FONTS')
                
            },
            fontactive: function(familyName, fvd) {
                console.log(`FONT: ${familyName} loaded`)
            },
            active: function() {
                // do some stuff with font   
                console.log("FONTS LOADED")
                console.timeEnd('LOAD_FONTS')
                setIsFontListLoaded(true)
            }
        });
    }, [])

    return isFontListLoaded
}