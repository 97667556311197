import React, {useState, useEffect} from 'react'

import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { createNewProject } from '../../utils/createUtils';

const SceneSettings = ({aspect, handleClose, storyInfo}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [aspectRatioValue, setAspectRatioValue] = useState('1/1')
    const [format, setFormat] = useState('landscape')
    const [projectNameValue, setProjectNameValue] = useState('New Video')
    const [modalDims, setModalDims] = useState({width: 320})

    useEffect(() => {
        if(aspectRatioValue){
            // var layout = getLayoutFromAspect(aspectRatioValue)
            // setFormat(layout)
            // console.log('Aspect Ratio Value: ', aspectRatioValue)
        }
    }, [aspectRatioValue])

    const updateAspect = (size) => {
        setAspectRatioValue(size)
    }

    const handleInput = (e) => {
        const value = e.target.value
        setProjectNameValue(value)
    }   

    useEffect(() => {
        console.log('STORY INFO ===> ', storyInfo)
        if(aspect){
            setAspectRatioValue(aspect)
        }
    }, [aspect])


    const startVideoCreationProcess = async () => {
        const {textBlob, projectId, selectedVoice, langCode, selectedCaptionIdx} = storyInfo;
        createNewProject(textBlob, projectId, projectNameValue, selectedVoice, langCode, selectedCaptionIdx, aspectRatioValue)
        handleClose()
        navigate(`/edit/${projectId}`);
    }

    return (
        <div style={{width: 300, margin: 20}}>
             <h4>Project Settings</h4> 
            
            <div style={{width: 300, float: 'left', marginTop: 30, marginBottom: 20 }}>
                <div style={{width: 300 }}>
                    <h6 style={{marginBottom: 15, color: '#212529'}}>Project Name</h6>
                    <input 
                        type='text' 
                        name='name' 
                        placeholder='Project Name' 
                        onChange={handleInput}
                        value={projectNameValue} 
                        style={{width: '100%', fontSize: 15, padding:5, borderRadius: 5, border: '1px solid #ddd'}}>
                    </input>
                        
                </div>
                <hr style={{border: '1px solid #ddd'}}/>
                <div className='row' style={{width: 300, marginTop: 20}}>
                    <h6 style={{marginBottom: 15, color: '#212529'}}>Select Size</h6>
                    <div className='col-sm-4 st-aspect-ratio-option-container' onClick={() => updateAspect('16/9')}>
                            <div className={aspectRatioValue == '16/9' ? 'aspect-ratio-option-selected' : 'aspect-ratio-option'} style={{ width: 80, height: 45}}>
                                { aspectRatioValue == '16/9' ? <div style={{ position: 'absolute',top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}><CheckCircleIcon style={{color: '#20bf55', fontSize: 24}}/></div> : null}
                            </div>
                            <div style={{margin: 'auto', marginTop: 5, width: '100%', textAlign: 'center', fontSize: 14  }}>16:9</div>
                            
                    </div>
                    <div className='col-sm-4 st-aspect-ratio-option-container'  onClick={() => updateAspect('9/16')}>
                        <div className={aspectRatioValue == '9/16' ? 'aspect-ratio-option-selected' : 'aspect-ratio-option'} style={{ width: 25, height: 45}}>
                            { aspectRatioValue == '9/16' ? <div style={{ position: 'absolute',top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}><CheckCircleIcon style={{color: '#20bf55', fontSize: 24}}/></div> : null}

                        </div>
                        <div style={{margin: 'auto', marginTop: 5, width: '100%', textAlign: 'center', fontSize: 14  }}>9:16</div>

                    </div>
                    <div className='col-sm-4 st-aspect-ratio-option-container' onClick={() => updateAspect('1/1')}>
                        <div className={aspectRatioValue == '1/1' ? 'aspect-ratio-option-selected' : 'aspect-ratio-option'} style={{ width: 45, height: 45}}>
                            { aspectRatioValue == '1/1' ? <div style={{ position: 'absolute',top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}><CheckCircleIcon style={{color: '#20bf55', fontSize: 24}}/></div> : null}

                        </div>
                        <div style={{margin: 'auto', marginTop: 5, width: '100%', textAlign: 'center', fontSize: 14  }}>1:1</div>

                    </div>
                </div>
                <div style={{width: '100%', marginTop: 15}}>
                    <div class='create-btn' style={{marginTop: 20, marginRight: 0}} onClick={startVideoCreationProcess}>
                        Create <i style={{fontSize: 14}} class="bi bi-chevron-right"></i>
                    </div>
                </div>
            </div>
           
            
        </div>
    )
}

export default SceneSettings
