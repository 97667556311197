import React, {useState, useEffect} from 'react'
import { setMenuIndexSelected } from '../../redux/storySlice'
import { menuIndex } from '../../constants/editorConfig';
import { useDispatch, useSelector } from 'react-redux'
import { isVideoAvailableForScene } from '../../utils/sceneUtils';
import '../../css/toolbar.css';

const SceneOptions = () => {
    const dispatch = useDispatch()

    const activeSceneIndex = useSelector(
        (state) => state.storyReducer.activeSceneIndex
    )

    const sceneInfo = useSelector(
        (state) => state.storyReducer.sceneInfoArray
    )

    const timeline = useSelector(
        (state) => state.storyReducer.timelineInfo
    )

    const [isVideoPresent, setVideoPresent] = useState(false)

    useEffect(() => {
        const scene = sceneInfo[activeSceneIndex]
        setVideoPresent(isVideoAvailableForScene(scene, timeline));
    }, [])

    const openMediaMenu = () => {
        dispatch(setMenuIndexSelected({menuIndexSelected: menuIndex.MEDIA_MENU_INDEX}))
    }

    return (
        <div style={{width: '100%', marginTop: 10}}>
            {/* <span style={{fontSize: 16,  fontWeight: 600, margin: 'auto', marginLeft: 20, color: '#424242'}}>
                Scene {activeSceneIndex+1}
            </span> */}
            <span className='edit-btn' onClick={openMediaMenu}>Add Media</span>
        </div>
    )
}

export default SceneOptions
