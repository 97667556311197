import React, {useState, useEffect, useRef} from 'react'
import { HexColorPicker } from "react-colorful";
import '../css/colorPicker.css';

import { useDispatch, useSelector } from 'react-redux'
import { setCaptionStyle } from '../redux/storySlice'


const ColorPicker = ({type, pos, mode}) => {
    const dispatch = useDispatch()

    const colorPickerRef = useRef()
    const swatchRef = useRef()
    const [color, setColor] = useState();
    const [showPicker, setShowPicker] = useState(false)
    const [panelPos, setPanelPos] = useState({top: 20, left: 20})

    const timeline = useSelector(
      (state) => state.storyReducer.timelineInfo
    )

    const captionStyle = useSelector(
      (state) => state.storyReducer.captionStyle
    )

    const selectedObjectId = useSelector(
      (state) => state.storyReducer.selectedObjectId
    )

    useEffect(() => {
      if(pos){
        switch(pos){
          case 'top': 
            setPanelPos({top: -290, left: 0})
            break;
          case 'botton':
            setPanelPos({top: 30, left: 0})
            break;
        }
      }
    }, [pos])

    useEffect(() => {
      const handler = (e) => {
        if (colorPickerRef.current && !swatchRef.current.contains(e.target) && !colorPickerRef.current.contains(e.target)) {
          setShowPicker(false);
        }
      };
  
      window.addEventListener("click", handler);
      return () => {
        window.removeEventListener("click", handler);
      };
    });


    useEffect(() => {
      console.log('COLOR =====> ', color)
        if(color && typeof(color) == 'string'){
            if(type == 'Stroke'){
              updateObjStyleInfo({fill: color})            
            }
            else if(type == 'Fill'){
              // Save Text Background
              updateObjStyleInfo({backgroundColor: color})         
            }
            else if(type == 'Background'){
              // Save Text Background
              console.log('BG Color Upate')
              console.log('Type: ', typeof(color))
              updateObjStyleInfo({fill: color})         
          }
        }
    }, [color])
    
    useEffect(() => { 
      if(mode == 'caption'){
        if(type == 'Stroke'){
          var colorFill = captionStyle?.fill
          console.log('Stroke: ', colorFill)
          // if(colorFill){
          //     setColor(colorFill)
          // }
          setColor(colorFill)
        }
        else if(type == 'Fill'){
          var bgColor = captionStyle?.backgroundColor
          console.log('Bg color: ', bgColor)
          // if(bgColor){
          //   setColor(bgColor)
          // } 
          setColor(bgColor) 
        }
      }else{
        var currentObj = getObjectFromId(selectedObjectId)
        console.log('Current Object: ', currentObj)
        if(currentObj){
          if(currentObj.type == 'text'){
            if(type == 'Stroke'){
              var colorFill = currentObj?.style?.fill
              console.log('Stroke: ', colorFill)
              if(colorFill){
                  setColor(colorFill)
              }
            }else if(type == 'Fill'){
              var bgColor = currentObj?.style?.backgroundColor
              console.log('Bg color: ', bgColor)
              if(bgColor){
                setColor(bgColor)
              }  
            }
          }
          else if(currentObj.type == 'rect' && type == 'Background'){
            var bgColor = currentObj?.style?.fill
            console.log('Bg color: ', bgColor)
            if(bgColor){
              if(typeof(bgColor) == 'string'){
                setColor(bgColor)
              }else{
                setColor()
              }
              
            } 
          }
        }
      }
      console.log('COLOR: ', color)
    }, [type, selectedObjectId, captionStyle?.id])

    useEffect(() => {
        const handler = (e) => {
          if (showPicker && colorPickerRef.current && !colorPickerRef.current.contains(e.target)) {
            console.log('close')
            setShowPicker(false);
          }
        };
    
        window.addEventListener("click", handler);
        return () => {
          window.removeEventListener("click", handler);
        };
    }, []);


    const getObjectFromId = (objId) => {
      if(objId){
        for (let i = 0; i < timeline.length; i++) {
          var obj = timeline[i];
          if (obj.id == objId) {
            return obj;
          }
        }
      }

      return null;
    }

    const updateObjStyleInfo = (styleInfo) => {
      console.log('Updating text style')
      if(mode == 'caption'){
        var newStyle = {...captionStyle, ...styleInfo}
        dispatch(setCaptionStyle({captionStyle: newStyle}))

      }else{
        var currentObj = getObjectFromId(selectedObjectId)
        console.log('Current Object: ', currentObj)
        if(currentObj){
          if(currentObj.type == 'text'){

          }else if(currentObj.type == 'rect'){

          }
        }
      }
    }

    const handleKeyDown = (e) => {
      console.log('Color Selected: ', e.target.value)
      if (e.key === 'Enter') {
        setColor(e.target.value);
      }
    };
  

    const openPicker = () => {
        console.log('Open ')
        setShowPicker(!showPicker)
    }

      return (
        <div style={{position: 'relative'}}>
          {
             mode == 'caption' ?
              <div style={{display: 'flex', border: '1px solid #ccc', height: 35, borderRadius: 4}}>
                <div className={color ? 'swatch': 'swatch-diag'} ref={swatchRef} style={{ background: color}} onClick={ openPicker }>

                  </div>
                  {
                    
                      <div style={{display: 'inline-block', width: '75%', height: '100%', fontSize: 18}}>
                        <input 
                            style={{  border: '0px solid', backgroundColor: '#fff', borderRadius: 3, marginTop: 0, width: '100%', height: '100%'}} 
                            type="text" 
                            value={color ?? ''} 
                            onInput={e => setColor(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                  }
              </div> : 
              <div ref={swatchRef} style={{ border: '1px solid #ccc', width: 30, height: 30, borderRadius: 4}} onClick={ openPicker }>

              </div>  
          }
         
          { showPicker ? 
            <div ref={colorPickerRef} className='popover' style={{top: panelPos.top, left: panelPos.left}}>
                {/* <div style={ styles.cover } onClick={onClose}/> */}
                <HexColorPicker color={color ?? '#ffffff'} onChange={setColor} />
                <div style={{ display: 'flex',width: '100%',  height: 35, border: '1px solid #fff', borderRadius: 5, marginTop: 15, }}>
                   
                      <div style={{ display: 'inline-block', width: '20%', height: '100%', }}>
                          <div style={{ width: '100%',  height: '100%', borderRight: '1px solid #ccc', background: color}} />
                      </div>
                      <div style={{display: 'inline-block', width: '79%', height: '100%' }}>

                          <input 
                            style={{  border: '0px solid', backgroundColor: '#fff', marginTop: 0, width: '100%', height: '100%'}} 
                            type="text" 
                            value={color && color.replace('#', '')} 
                            onInput={e => setColor('#'+e.target.value)}
                            onKeyDown={handleKeyDown}
                          />
                      </div>
                </div>
            </div> : null }
 
        </div>
      )
    
}

export default ColorPicker
