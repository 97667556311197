import React, {useRef, useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../../css/editor.css';

export default function AlertDialog({open, handleClose, source}) {

    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')

    const [mode, setMode] = useState('alert-box')

    useEffect(() => {
        if(source){
            console.log('SOURCE: ', source)
            switch(source){
                case 'story-options':
                    console.log('PROMPT ALERT FOR STORY OPTIONS');
                    setTitle('SCRIPT UPDATE')
                    setMode('alert-box')
                    setContent('Updating script will remove all the updates done and storyboard will refresh. Are you sure you want to continue? ')
                    break;
                case 'audio-options':
                    setTitle('UPDATE VOICE')
                    setContent('Please wait while we are regenerating the new voiceover...')
                    setMode('processing')
                    break;
                default:
                    console.log('INVALID SOURCE SELECTED');
            }
        }
        
    }, [source])


  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        {
            mode == 'alert-box' ? 
                <>
                    <DialogTitle id="alert-dialog-title">
                        {title}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {content}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleClose} autoFocus>
                        Continue
                    </Button>
                    </DialogActions>
                </> :
                <>
                <DialogContent>
                    <div style={{margin: 'auto', marginBottom: 30}}>
                        <div className="loading-bar">
                        </div>
                        <div style={{textAlign: 'center', fontSize: 14, opacity: 0.6}}>Processing...</div>
                    </div>
                    <DialogContentText id="alert-dialog-description">
                        {content}
                    </DialogContentText>
                </DialogContent>
            </>
        
        }
       
      </Dialog>
    </>
  );
}
