import { fabric } from "fabric";

const CustomTextbox = fabric.util.createClass(fabric.Textbox, {
  type: "textbox",

  toObject: function () {
    return fabric.util.object.extend(this.callSuper("toObject"), {
      backgroundColor: this.backgroundColor,
      padding: this.padding,
      borderRadius: this.borderRadius,
      maxWidth: this.maxWidth,
    });
  },

  initialize: function (text, options) {
    options || (options = {});
    this.callSuper("initialize", text, options);
    this.set({
      backgroundColor: options.backgroundColor || "",
      padding: options.padding || 0,
      borderRadius: options.borderRadius || 0,
      maxWidth: options.maxWidth || 800,
    });
  },

  _drawRoundRect: function (ctx, x, y, width, height, radius) {
    if (typeof radius === "undefined") {
      radius = 5;
    }
    if (typeof radius === "number") {
      radius = { tl: radius, tr: radius, br: radius, bl: radius };
    } else {
      radius = {
        tl: radius.tl || 0,
        tr: radius.tr || 0,
        br: radius.br || 0,
        bl: radius.bl || 0,
      };
    }

    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y); // Top-left corner
    ctx.lineTo(x + width - radius.tr, y); // Top edge
    ctx.arc(
      x + width - radius.tr,
      y + radius.tr,
      radius.tr,
      Math.PI * 1.5,
      Math.PI * 2
    ); // Top-right corner
    ctx.lineTo(x + width, y + height - radius.br); // Right edge
    ctx.arc(
      x + width - radius.br,
      y + height - radius.br,
      radius.br,
      0,
      Math.PI * 0.5
    ); // Bottom-right corner
    ctx.lineTo(x + radius.bl, y + height); // Bottom edge
    ctx.arc(
      x + radius.bl,
      y + height - radius.bl,
      radius.bl,
      Math.PI * 0.5,
      Math.PI
    ); // Bottom-left corner
    ctx.lineTo(x, y + radius.tl); // Left edge
    ctx.arc(x + radius.tl, y + radius.tl, radius.tl, Math.PI, Math.PI * 1.5); // Top-left corner
    ctx.closePath();
    // ctx.stroke();
  },

  set: function (key, value) {
    if (typeof key === "object") {
      // If an object is passed, call set for each property
      for (var prop in key) {
        this.set(prop, key[prop]);
      }
    } else {
      // Custom logic before setting the property
      if (["text", "fontSize, fontFamily"].includes(key)) {
        console.log("Setting TEXt:", value);
        // this.set({ width: 300 });
        this._autoAdjustWidth(value);
      }

      // Call the parent class's set method
      this.callSuper("set", key, value);

      // Custom logic after setting the property (if needed)
      // For example, re-render the object
      this.canvas && this.canvas.renderAll();
    }

    return this;
  },

  _autoAdjustWidth: function (text) {
    // Create a temporary canvas context to measure text width
    var ctx = this.canvas
      ? this.canvas.getContext()
      : document.createElement("canvas").getContext("2d");
    ctx.font = this._getFontDeclaration(); // Use the current font style
    var textWidth = ctx.measureText(text).width;

    console.log("MAX WIDTH: ", this.maxWidth);
    console.log("TEXT WIDTH: ", textWidth);

    if (textWidth > this.maxWidth) {
      textWidth = this.maxWidth;
    }

    // Set the new width considering padding
    var padding = this.padding || 0;
    this.set("width", textWidth + padding * 2);
  },

  _renderBackground: function (ctx) {
    if (!this.backgroundColor) {
      return;
    }

    ctx.fillStyle = this.backgroundColor;
    var w = this.width,
      h = this.height,
      r = Math.min(this.borderRadius, w / 2, h / 2),
      x = -w / 2,
      y = -h / 2;

    ctx.beginPath();
    // ctx.roundRect(10, 20, 150, 100, [40]);
    // ctx.roundRect(
    //   -w / 2 - this.padding,
    //   -h / 2 - this.padding,
    //   w + this.padding * 2,
    //   h + this.padding * 2,
    //   r
    // );

    this._drawRoundRect(
      ctx,
      -w / 2 - this.padding,
      -h / 2 - this.padding,
      w + this.padding * 2,
      h + this.padding * 2,
      r
    );
    ctx.fill();

    this.callSuper("_render", ctx);

    ctx.restore();
  },
});

export { CustomTextbox };
