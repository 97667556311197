import React, {useState, useEffect} from 'react'
import '../css/input.css'

const Sidebar = ({active, setActive}) => {

    let menuItems = [
        {
            name: 'New Video',
            iconClass: 'bi-play-fill'
        },
        {
            name: 'My Videos',
            iconClass: 'bi-folder'
        },
    ]

    return (
        <>
            <div className='sidebar-menu'>
                {menuItems.map((item, index) => (
                    <div className={`${active === index ? "box-item-active" : "box-item-container"}`} onClick={() => setActive(index)}>
                        <span style={{fontSize: '1.5rem'}}>
                            <i class={`bi ${item.iconClass}`}></i>
                        </span>
                        <p className='menu-description'>{item.name}</p>
                    </div>
                    )
                )}
            </div>
        </>
    )
}

export default Sidebar
