import React, { useState, useRef, useEffect } from "react";
import { fabric } from "fabric";
import { useDispatch, useSelector } from 'react-redux'
import {  setSceneInfo } from '../redux/storySlice'
import { renderFactory } from "../utils/renderUtils";
import {
    getPreviewContainerDimensions, containerHeight
} from "../constants/canvasSettings";
import { playerModes, composeScene } from "../utils/sceneUtils";
import { createSceneBounds } from "../utils/createUtils";

let playing = false;
var timeTracker;
const audioCtx = new (window.AudioContext || window.webkitAudioContext)();

const createWaitLock = () => {
    let resolve;
    const promise = new Promise((res) => {
      resolve = res;
    });
    return { promise, resolve };
};
  

const Preview = ({setCloseModal, invokeCleanup}) => {
    const dispatch = useDispatch()

    const canvasRef = useRef();
    const fabricRef = useRef();
    const clipPathRef = useRef(null);
  
    const videoRef = useRef({});
    const textRef = useRef([]);

    const renderRef = useRef();

    const wordTimestamps = useRef([])
    const wordsIndexTracker = useRef()
    const wordIndexInString = useRef()

    const animationFrameId = useRef(null)

    const currentSceneRef = useRef(null)

    const currentTimeRef = useRef(0)
    const renderInitTime = useRef()

    const progressRef = useRef([])
    const playRef = useRef()

    const audioBuffer = useRef()
    const audioSourceRef = useRef()

    const waitLockRef = useRef(null);

    const [isPlaying, setPlaying] = useState(false)
    const [currentRenderState, setCurrentRenderState] = useState({currentSceneId: 0, time: 0})

    const [containerParams, setContainerParams] = useState();
    const [scenes, setScenes] = useState([]);

    const [currentSceneThumbnail, setCurrentSceneThumbnail] = useState()

    const [isErrorDisplayed, setErrorDisplayed] = useState(true)
    const [error, setError] = useState('Some lag may appear between scenes which will be removed in the final video after rendering.')

    const timeline = useSelector(
        (state) => state.storyReducer.timelineInfo
    )

    const audioInfo = useSelector(
        (state) => state.storyReducer.audioInfo
    )

    const captionStyle = useSelector(
        (state) => state.storyReducer.captionStyle
    )

    const storySettings = useSelector(
        (state) => state.storyReducer.settings
    )

    useEffect(() => {
        const containerSettings = getPreviewContainerDimensions(storySettings.aspectRatio);
        setContainerParams(containerSettings);
        console.log("VIDOE REFS: ", videoRef);

        waitLockRef.current = createWaitLock();

        if(!scenes.length){
        getSceneArrayFromInfo()

        }
        return () => {
            // Cleanup functions
        };
    }, []); 

    useEffect(() => {
        if(audioInfo){
            loadAudio()
        }
    }, [audioInfo])
  
    useEffect(() => {
        console.log('INVOKE CLEANUP: ', invokeCleanup)
        const cleanupProcesses = async () => {
            console.log('Starting Cleanup Process')
            stopPlaying()
            waitLockRef.current.resolve();
            playRef.current = false;
            setCloseModal(true)
        }

        if(invokeCleanup){
            cleanupProcesses()

        }
    }, [invokeCleanup])


    useEffect(() => {
        // if (!canvasRef.current) return;
        // if (fabricRef.current) return;
        if (containerParams && scenes) {
            console.log('Setting Up Stage')
            const canvas = new fabric.Canvas('canvas-preview', {
                width: containerParams.viewWidth,
                height: containerParams.viewHeight,
                preserveObjectStacking: true,
                backgroundColor: "#EBECF0",
            });
    
            fabricRef.current = canvas;
    
            // Set total scene count
            console.log('Setting up stage')
            setCurrentScene(scenes[0])
        }
    
    }, [containerParams, scenes]);


    const closeErrorDisplay = () => {
        setErrorDisplayed(false)
    }


    const setCurrentScene = (scene) => {
        currentSceneRef.current = scene;
        console.log('SCENE: ', scene)
        composeSceneWithId(0)
    }

    const getSceneArrayFromInfo = () => {
        console.log('UPDATING SCENES: ', audioInfo.sentenceRangeMap)
       
        console.log('TIMELINE: ', timeline)
        const sceneBoundsArray = createSceneBounds(audioInfo.sentenceRangeMap, audioInfo.duration)
        setScenes(sceneBoundsArray)
        dispatch(setSceneInfo({sceneInfo: sceneBoundsArray}))
    }

    const loadAudio = () => {
        return new Promise((res, rej) => {
            const request = new XMLHttpRequest();
          
            request.open(
                "GET",
                audioInfo.url,
                true
            );
            request.responseType = "arraybuffer";
    
            request.onload = function () {
                console.log("Request Response: ", request.response);
                audioCtx.decodeAudioData(request.response, function (buffer) {
                    audioBuffer.current = buffer;
                    createAudioSource()
                    res('Loaded Audio Successfully!')
                });
            };
            request.send();
        });
    }

    const createAudioSource = async () => {
        if(!audioBuffer.current){
            await loadAudio()
        }
        let audioSource = audioCtx.createBufferSource();
        audioSource.buffer = audioBuffer.current;
        audioSource.connect(audioCtx.destination);

        audioSourceRef.current = audioSource
        return audioSource
    }
    
    function inRange(x, min, max) {
        return (x - min) * (x - max) <= 0;
    }

    const stopPlaying = () => {
        /* Preview is paused */
        console.log('PREVIEW PAUSED!')
        playing = false;
        playRef.current = false;
        setPlaying(false)
        setCurrentRenderState({currentSceneId: currentSceneRef.current?.id, time: currentTimeRef.current})
        audioSourceRef.current.stop()
        // console.log('CURRENT RENDER STATE: ', {currentSceneId: currentSceneTrackerId, time: timeTracker})
    }

    const endPlaying = () => {
        /* Preview has finished playing */
        console.log('END PLAYING CALLED')
        playing = false;
        playRef.current = false;
        setPlaying(false)
        setCurrentRenderState({currentSceneId: 0, time: 0})
        if(currentTimeRef.current >= scenes[scenes.length-1].endTime){
            currentSceneRef.current = scenes[0]
            currentTimeRef.current = 0
        }
      
    }

    const playPreview = async () => { 
        if(!playing){
            console.log('PLAYiNG SCENE: ', currentSceneRef.current)
            await playScene(currentSceneRef.current?.id)
            // await playScene(0)
      
           console.log('STOP PREVIEW')
        }else{
            console.log('STOPPED AT SCENE: ', currentSceneRef.current)
            console.log('STOPPED AT TIME: ', currentTimeRef.current)
            
            if (waitLockRef.current) {
                waitLockRef.current.resolve();
            }
            stopPlaying()
        }
    }

    function delay(time) {
        return new Promise(function (resolve) {
          setTimeout(resolve, time);
        });
      }

    const playScene = async (i) => {
        if(!playing){
            timeTracker = 0;
            var canvas = fabricRef.current
            canvas.remove(...canvas.getObjects().concat());

            await delay(1000);
    
            // animationTimeline.current = []
    
            for(let k=0;  k < scenes.length; k++){
                if(k >= i){
                    progressRef.current[k].style.width = `0%`;
                }else{
                    progressRef.current[k].style.width = `100%`;
                }
            }
            playing = true;
            setPlaying(true)
            playRef.current = true;
    
            await composeSceneWithId(i)
            await startVideo(i)
            await resetProgressForScenes()

            endPlaying()
        }else{
            stopPlaying()
        }
       
    }

    const resetProgressForScenes = async () => {
        for(let i=0; i < scenes.length; i++){
            progressRef.current[i].style.width = `0%`;
        }
        
    }

    const updateProgressBar = (i, duration) => {
        return new Promise((res, rej) => {
            var currTime = 0
            const durationInMillis = duration * 1000;
            const interval = setInterval(() => {
                currTime = currTime + 30;
                
                console.log('PROGRESS BAR::', currTime*100/(durationInMillis))
                progressRef.current[i].style.width = `${currTime*100/(durationInMillis)}%`;
                if(!playRef.current){
                    clearInterval(interval);
                    res()
                }
                if(currTime >= durationInMillis){
                    progressRef.current[i].style.width = i == scenes.length-1 ? `0%` : `100%`
                    clearInterval(interval);
                    res()
                }
            }, 30)
        })
       
        
    }


    const startVideo = async (i) => {
        let currentSceneId = i;
        let bufferSceneId = currentSceneId + 1

        for( bufferSceneId ; bufferSceneId <= scenes.length; bufferSceneId++){
            console.log('PLAYING: ', playing)

            // For the last scene
            if(bufferSceneId == scenes.length){
                await startRenderAnimation(scenes.length-1);
                await composeSceneWithId(0);
                await cleanupScene(scenes.length-1);
               
                break;
            }
            
            console.log('PREVIEW: RENDERING CURRENT SCENE: ', currentSceneId)

            await startRenderAnimation(currentSceneId);

            if(!playing){
                break;
            }

            console.log('PREVIEW: COMPOSING BUFFER SCENE: ', bufferSceneId)
            await composeSceneWithId(bufferSceneId);

           
            console.log('PREVIEW: CLEANUP CURRENT SCENE: ', currentSceneId)

            // await sceneTransition(fabricRef.current, bufferSceneId)
            await cleanupScene(currentSceneId);
            setCurrentRenderState({currentSceneId: bufferSceneId, time: 0})

            currentSceneId = bufferSceneId;
        }

        currentSceneRef.current = scenes[0];

    }
    
    const startRenderAnimation = (sceneId) => {
        return new Promise(async (res, rej) => {
            renderInitTime.current = performance.now() / 1000; // Get the start time in seconds

            console.log('Starting Render')
            // const sceneInfo = currentSceneRef.current
            const sceneInfo = scenes[sceneId]
            currentSceneRef.current = sceneInfo;
            currentTimeRef.current = sceneInfo.start

            const sceneDuration = sceneInfo.end - sceneInfo.start;
    
            console.log('Scene Info: ', sceneInfo)
    
            const audioSource = await createAudioSource()
            audioSourceRef.current = audioSource
    
            audioSource.start(0, sceneInfo.start);
            // animationFrameId = requestAnimationFrame(render);
    
            var renderer = renderFactory(
                sceneInfo,
                renderInitTime,
                timeline,
                fabricRef,
                clipPathRef,
                videoRef,
                textRef,
                captionStyle,
                null,
                audioSourceRef,
                wordTimestamps,
                wordsIndexTracker,
                wordIndexInString,
                waitLockRef,
                playerModes.PREVIEW
            )

            renderRef.current = renderer;

            animationFrameId.current = renderer.start()
            console.log('ANIMATION FRAME ID: ', animationFrameId.current)

            updateProgressBar(sceneId, sceneDuration)
            waitLockRef.current = createWaitLock()
            await waitLockRef.current.promise;
            
            renderer.stop()
            res()
        })

    }

    async function composeSceneWithId(sceneId){
        const sceneInfo = scenes[sceneId]

        await composeScene(
            sceneInfo,
            audioInfo,
            timeline,
            fabricRef.current,
            containerParams,
            clipPathRef,
            videoRef,
            textRef,
            captionStyle,
            wordTimestamps,
            wordsIndexTracker,
            wordIndexInString,
            playerModes.PREVIEW
        )
    }

    const cleanupScene = async (sceneId) => {
        if(playing){
            var canvas = fabricRef.current;
            var objs = canvas.getObjects();
            var canvas = fabricRef.current
            objs.forEach((obj) => {
                if(obj.sceneId == sceneId){
                    console.log("PREVIEW: CLEANUP OBJ: ", obj)
                    canvas.remove(obj)
                }
            })
        }
    }
    
    return (
        <div style={{width: '100%', height: '90vh', paddingInline: 20}}>
            <div style={{ display: isErrorDisplayed ? 'block': 'none', position: 'relative',padding: 8, width: '90%', margin: 'auto', marginTop: 5, borderRadius: 5, backgroundColor: '#dfdbfb', color: '#584efd'}}>
                <span style={{width: '85%'}}>{error}</span>
                <div style={{position: 'absolute', top: 7, right: 7, textAlign: 'right', fontSize: 18, width: '10%', cursor: 'pointer'}} onClick={closeErrorDisplay}>
                    <i class="bi bi-x"></i>
                </div>
            </div>
            {
                containerParams && 
                    <div style={{width: containerParams.width }}>
                        <div style={{height: containerParams.viewHeight, width: containerParams.viewWidth, border: '1px solid #ccc', margin: 'auto', marginTop: 40}}>
                            <canvas id="canvas-preview" ref={canvasRef} style={{}}>

                            </canvas>
                        </div>
                        <div style={{ width: containerHeight,  margin: 'auto',}}>
                            <div style={{ display: 'inline-block', cursor: 'pointer', textAlign: 'left', marginTop: 40}} onClick={playPreview} >
                                {
                                    isPlaying ? 
                                        <i style={{color: '#36454F', fontSize: 35}} class="bi bi-pause-circle-fill"></i> : 
                                        <i style={{color: '#36454F', fontSize: 35}} class="bi bi-play-circle-fill"></i>
                                } 
                            </div>
                            <div style={{display: 'inline-block', width: 350, height: 12, marginLeft: 15}}>
                                {/*  */}
                                {
                                    scenes.map((scene, i) => (
                                        <div style={{ display: 'inline-block', width: `${scene.duration*100/audioInfo.duration}%`, marginBottom: 7, height: 12, cursor: 'pointer', backgroundColor: '#eee', border: '1px solid #ddd'}} onClick={() => playScene(i)}>
                                            <div ref={el => (progressRef.current[i] = el)} style={{ width: '0%', height: 10, marginBottom: 7, backgroundColor: '#584efd'}}></div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }

            
        </div>
    )
}

export default Preview
