import React, { useState, useEffect } from "react";
import isUserSubscribed from "./isUserSubscribed";

export default function useSubscribedStatus(user) {
  const [isSubscribed, setSubscribedStatus] = useState(false);

  useEffect(() => {
    if (user) {
      const checkSubscribedStatus = async function () {
        setSubscribedStatus(await isUserSubscribed());
      };
      checkSubscribedStatus();
    }
  }, [user]);

  return isSubscribed;
}
