import axios from "axios";

async function processTextToSpeech(projectId, text, voice, langCode) {
  console.log(
    `Calling text to speech API with Text: ${text} and Voice: ${voice}`
  );
  var data = {
    projectId,
    text,
    voice,
    langCode,
  };
  try {
    console.log(
      `SERVER ENDPOINT: ${process.env.REACT_APP_SERVER_ENDPOINT}/speech`
    );
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/speech`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          referrerPolicy: "unsafe-url",
        },
      }
    );
    let result = response.data;
    console.log("Response: ", result);
    return result;
  } catch (error) {
    console.log("Error Occurred");
    console.log(error);
    return { message: `Error: ${error}` };
  }
}

export { processTextToSpeech };
