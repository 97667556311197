import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CaptionEditOptions from './CaptionEditOptions'
import MediaEditOptions from './MediaEditOptions'
import SceneOptions from './SceneOptions'

const EditOptions = () => {

    const [currentObjType, setCurrentObjType] = useState()

    const timeline = useSelector(
        (state) => state.storyReducer.timelineInfo
    )  

    const selectedObjectId = useSelector(
        (state) => state.storyReducer.selectedObjectId
    )

    useEffect(() => {
        if(selectedObjectId !== null || selectedObjectId !== undefined){
            console.log('SELECTED OBJ ID::', selectedObjectId)
            if(selectedObjectId == 'cpt-txt'){
                setCurrentObjType('caption')
            }else{
                var currentObj = getObjectFromId(selectedObjectId)
                console.log('CURRENT OBJ:===>', currentObj)
                setCurrentObjType(currentObj?.type)

            }
            
        }
    }, [selectedObjectId])

    const getObjectFromId = (objId) => {
        console.log('TIMELINE: ', timeline)
          for (let i = 0; i < timeline.length; i++) {
            var obj = timeline[i];
            if (obj.id == objId) {
              return obj;
            }
        }
  
        return null;
      }

    const handleSelectionObjectChange = () => {
        console.log('CURRENT OBJ TYPE::==> ', currentObjType)
        switch(currentObjType){
            case 'caption':
                return <CaptionEditOptions />
            case 'video':
                return <MediaEditOptions />
            default:
                return <SceneOptions />
        }  
    }


    return (
        <div style={{height: 48, width: '100%', padding: 5, paddingLeft: 15}}>
           {handleSelectionObjectChange()}
        </div>
    )
}

export default EditOptions
