import React from 'react'
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material'
import SceneSettings from './SceneSettings';

const SettingsModalView = ({open, handleClose, aspect, storyInfo}) => {
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='lg'
            >
                <DialogContent style={{position: 'relative'}}>
                    <SceneSettings aspect={aspect} handleClose={handleClose} storyInfo={storyInfo}/>
                    <div style={{position: 'absolute', right: 15, top: 15, fontSize: 18, cursor: 'pointer' }} onClick={handleClose}><i class="bi bi-x-lg"></i></div>
                </DialogContent>
           
           </Dialog>
       
       </>
    )
}

export default SettingsModalView
