import axios from "axios";

export const getExecutionStatus = async (jobId) => {
  console.log(`Calling Execution Status Fetch API with Job-Id: ${jobId}`);
  console.log(`${process.env.REACT_APP_SERVER_ENDPOINT}/gateway`);
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/gateway`,
      {
        params: {
          jobId: jobId,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    let result = response.data.data;
    console.log("Execution Function Status Response: ", result);
    return result;
  } catch (error) {
    console.log("Error Occurred");
    console.log(error);
    return { message: `Error: ${error}` };
  }
};
