import React, {useRef, useState, useEffect} from 'react'
import { availableLanguages, voices, getLanguageCode } from '../constants/speechConfig';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux'
import { setAudioInfo, setSceneInfo, setAlertDialogInfo } from '../redux/storySlice';
import { processTextToSpeech } from "../services/speech";


const AudioOptions = () => {
    const dispatch = useDispatch()

    const audioRef = useRef()
    const [isPlaying, setIsPlaying] = useState(false)

    const [language, setLanguage] = useState('')
    const [gender, setGender] = useState('')

    const [isMaleChecked, setMaleChecked] = useState(false)
    const [isFemaleChecked, setFemaleChecked] = useState(false)
    const [genderArray, setGenderArray] = useState([])

    const [voiceSelected, setVoiceSelected] = useState()
    const [activeVoice, setActiveVoice] = useState()
    const [btnDisplay, setBtnDisplay] = useState();

    const [displayVoices, setDisplayVoices] = useState([])

    const [voiceOptions, setVoiceOptions] = useState()

    const audioInfo = useSelector(
        (state) => state.storyReducer.audioInfo
    )

    const storySettings = useSelector(
        (state) => state.storyReducer.settings
    )

    const genderOptions = [
        {value: 'All', label: 'All'},
        {value: 'Male', label: 'Male'},
        {value: 'Female', label: 'Female'}
    ]

    useEffect(() => {
        // if(!voiceOptions && availableLanguages){
        //     var voiceList = []
        //     availableLanguages.map((lang) => {
        //         voiceList.push({label: lang, value: lang})
        //     })
        //     setVoiceOptions(voiceList)
           
        // }
        getEnglishVoices()
    }, [])

    useEffect(() => {
        // console.log('LANG: ', language)
        // console.log('GENDER: ', gender)
        // var voiceList = voices.filter((obj) => {
        //     return language == obj.LocaleName && gender == obj.Gender;
        //   });
        // setDisplayVoices(voiceList)
        // console.log('DiSPLAY VOICES: ', voiceList)
    }, [language, gender])

    useEffect(() => {
        if(genderArray.length){
            console.log('GENDER ARR: ', genderArray )
            var voiceList = voices.filter((obj) => {
                return obj.Personality != null && genderArray.includes(obj.Gender)
            });
            setDisplayVoices(voiceList)
        }else{
            var voiceList = voices.filter((obj) => {
                return obj.Personality != null
            });
            setDisplayVoices(voiceList)
        }
    }, [genderArray])
    

    const getEnglishVoices = () => {
        var voiceList = voices.filter((obj) => {
            return obj.Personality != null;
        });
        console.log('ENGLISH VOICES COUNT: ', voiceList.length)
        setDisplayVoices(voiceList)

        // setVoiceSelected(voiceList[0].ShortName)
        setVoiceSelected(audioInfo?.voice ?? voiceList[0])
    }

    const handleClick=(e, voice)=>{
        var audioElm = audioRef.current
        if(isPlaying && activeVoice == voice.ShortName){
            setIsPlaying(false)
            audioElm.pause()
        }else{
          setIsPlaying(true)
          setActiveVoice(voice.ShortName)

          audioElm.src = `https://res.cloudinary.com/dlmsxlf2n/video/upload/v1723737009/Diveo/Assets/audio/voice/${voice.ShortName}.wav`;
          audioElm.volume = 0.5
          audioElm.play()
        }
    }

    const endMusic = (e) => {
        console.log('VIDEO ENDED!!')
        setIsPlaying(false)
    }

    const selectVoice = async (voice) => {
        console.log('GENERATING NEW VOICEOVER FOR : ', voice)
        dispatch(setAlertDialogInfo({info: {isOpen: true, source: 'audio-options'}}))
        setVoiceSelected(voice)

        const projectId = storySettings.projectId;
        const text = audioInfo.text;
        const langCode = getLanguageCode(language)

        console.log('Project ID: ', projectId);
        console.log('Text: ', text);
        console.log('Lang Code: ', langCode);

        console.log('Voice Selected: ', voiceSelected)

        const result = await processTextToSpeech(projectId, text, voice, langCode);
        console.log('SPEECH PROCESSING COMPLETED!!')
        console.log('Result:: ', result);

        dispatch(setSceneInfo({sceneInfo: []}))
        dispatch(setAudioInfo({ audioInfo: result.data }));
        dispatch(setAlertDialogInfo({info: {isOpen: false}}))
        

    }

    const selectGender = (gender) => {
        console.log('GENDER SELECTED: ', gender)
        setGender(gender.value)
    }

    const selectLanguage = (lang) => {
        console.log('LANGUAGE SELECTED: ', lang)
        setLanguage(lang.value)
    }

    const onGenderFilterChange = (gender) => {
        if(gender == 'Male'){
            setMaleChecked(!isMaleChecked)
        }else{
            setFemaleChecked(!isFemaleChecked)
        }
        if(genderArray.includes(gender)){
            console.log('GENDER ARR: ', genderArray)
            var newArr = genderArray.filter(function(item) {
                return item !== gender
            })
            console.log('NEW ARR: ', newArr)
            setGenderArray([...newArr])
        }else{
            setGenderArray([...genderArray, gender])
        }
    }

    return (
        <div style={{height: '100%', padding: 10, overflow: 'scroll'}}>
            <audio ref={audioRef} onEnded={endMusic}></audio>

            <div style={{fontSize: 18,  fontWeight: 700, margin: 'auto', marginTop: 10, color: '#424242'}}>Select Voiceover </div>

            {/* <div style={{marginTop: 30, marginBottom: 8, fontWeight: 700, color: '#424242'}}>Language</div>
            <div style={{ width: 250, marginRight: 5}}>
                <Dropdown
                    isSearchable={false}
                    menuSize={250}
                    placeHolder='Select Language'
                    options={voiceOptions}
                    onChange={(value) => selectLanguage(value)}
                />
            </div> */}
            
            {/* <div style={{marginTop: 15, marginBottom: 8, fontWeight: 700, color: '#424242'}}>Gender</div>
            <div style={{ width: 250, marginRight: 5}}>
                <Dropdown
                    isSearchable={false}
                    menuSize={250}
                    placeHolder='Select Gender'
                    options={genderOptions}
                    onChange={(value) => selectGender(value)}
                />
            </div> */}
             <div style={{ width: 250, marginRight: 5, marginTop: 15, fontSize: 16}}>
                 <div style={{display: 'inline-block'}}>
                    <input type='checkbox' checked={isMaleChecked} onChange={() => onGenderFilterChange('Male')}/>
                    <label style={{marginLeft: 5}}>Male</label>
                 </div>
                 <div style={{display: 'inline-block', marginLeft: 20}}>
                    <input type='checkbox' checked={isFemaleChecked} onChange={() => onGenderFilterChange('Female')}/>
                    <label style={{marginLeft: 5}}>Female</label>
                 </div>

                
             </div>


            <div style={{marginTop: 50, width: '95%'}}>
                {
                    displayVoices.map((voice) => 
                        <div className='row' style={{padding: 15, paddingInline:10, width: '100%', position: 'relative', margin: 'auto', marginBottom: 15, border: voiceSelected.ShortName == voice.ShortName ? '2px solid #584efd': '1px solid #eee', borderRadius: 5}} onMouseEnter={() => setBtnDisplay(voice.ShortName)} onMouseLeave={() => setBtnDisplay(null)}>
                           <div className='col-2' style={{cursor: 'pointer'}}  onClick={(e)=>handleClick(e, voice)}>
                                {isPlaying && activeVoice == voice.ShortName ? 
                                    <StopCircleIcon style={{ fontSize: 35}} /> : 
                                    <PlayCircleFilledIcon style={{ fontSize: 35, color: 'rgba(91, 68, 237, 1)'}}/>
                                }
                            </div>
                            <div className='col-8' style={{display: 'inline-block'}}>
                                <div style={{fontSize: 18, fontWeight: 700, color: '#424242'}}>{voice.DisplayName} </div> 
                                <div style={{marginBottom: 10}}>{voice.Gender}, {voice.AgeGroup == 'Young' ? 'Young' : 'Adult'}</div>
                                <div style={{fontSize: 14}}>
                                    {
                                        voice.Personality.map((feature) => 
                                            <span style={{marginRight: 5, marginBottom: 20, padding: 2, paddingInline: 8, backgroundColor: 'rgba(91, 68, 237, 0.8)', color: '#fff', borderRadius: 10}}>
                                                {feature}
                                            </span>
                                        )
                                    }
                                </div>
                            </div>
                            
                           {
                               voice.ShortName == btnDisplay &&
                                <div className='col-2' style={{ position: 'absolute', right: 10, cursor: 'pointer', display: 'inline-block', marginLeft: 30, fontSize: 14, borderRadius: 8, padding: 3, paddingInline: 6}} onClick={()=>selectVoice(voice)}>
                                    {
                                        voice.ShortName != voiceSelected.ShortName && 
                                            <Tooltip title="Apply">
                                                <span><AddCircleOutlineIcon style={{ color: '#584efd', fontSize: 25}}/></span>
                                            </Tooltip>
                                    }
                                </div>
                           }
                           {
                                voice.ShortName == voiceSelected.ShortName &&  
                                <div className='col-2' style={{ position: 'absolute', right: 10, cursor: 'pointer', display: 'inline-block', marginLeft: 30, fontSize: 14, borderRadius: 8, padding: 3, paddingInline: 6}}>
                                    <span><CheckCircleIcon style={{color: '#20bf55', fontSize: 25}}/></span>
                                </div>
                            }
                        </div>
                    )
                }
            </div>
            
            
        </div>
    )
}

export default AudioOptions
