import React, { useEffect} from 'react'
import {signInUserWithEmail} from '../services/auth'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from "react-router-dom";

const LoginWithEmail = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const authState = useSelector(
        (state) => state.authReducer
    )

    useEffect(() => {
        if(authState.isAuthenticated){
            // Navigate to storyboard
            navigate(state?.path || "/home");

        }else{
            // Navigate to login page
        }
     }, [authState])
 
     useEffect(() => {
         console.log('Logging In')
         const cachedEmail = window.localStorage.getItem("diveoEmailForSignIn");
         if(!!cachedEmail){
             // If the user has code, sign the user in
             signInUserWithEmail(cachedEmail)
         }
     }, [])
    
    return (
        <div>
            
        </div>
    )
}

export default LoginWithEmail
