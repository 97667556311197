import { fabric } from "fabric";
import { addOrReplaceVideo } from "./renderUtils";
import { updateLatestActionStatus, updateTimeline } from "../redux/storySlice";
import { store } from "../redux/store";

const actionType = {
  REPLACE_MEDIA: "replace_media",
  ADD_MEDIA: "add_media",
  CAPTION_STYLE: "edit_captions",
};

const actionStatus = {
  PROCESS: 0,
  COMPLETE: 1,
};

const createVideoElement = (videoInfo) => {
  var video = document.createElement("video");
  video.crossorigin = "anonymous";
  video.muted = true;
  video.height = videoInfo.height;
  video.width = videoInfo.width;
  video.src = videoInfo.url;

  return video;
};

const updateTimelineInfo = (timelineInfo, targetObjId, objInfo) => {
  var newTimelineInfo = [...timelineInfo];

  console.log("Old Timeline Info: ", timelineInfo);
  var objectIndex = -1;
  timelineInfo.map((obj) => {
    console.log("Obj: ", obj);
    if (obj.id == targetObjId) {
      objectIndex = obj.id;
    }
  });

  const newInfo = {
    url: objInfo.url,
    height: objInfo.height,
    width: objInfo.width,
  };
  if (objectIndex >= 0) {
    var obj = newTimelineInfo[objectIndex];
    newTimelineInfo[objectIndex] = {
      ...obj,
      info: newInfo,
      pos: null,
      scaleX: null,
      scaleY: null,
    };
  } else {
    // New Object is Created
    var newObjInfo = {
      info: newInfo,
      id: objInfo.id,
      sceneId: objInfo.sceneId,
      startTime: objInfo.startTime,
      endTime: objInfo.endTime,
      type: objInfo.type,
    };
    newTimelineInfo.push(newObjInfo);
  }

  store.dispatch(updateTimeline({ timelineInfo: newTimelineInfo }));

  console.log("New Timeline Info: ", newTimelineInfo);
};

const sendActionForProcessing = async (
  sceneId,
  action,
  timelineInfo,
  containerParams,
  canvas,
  videoRef,
  clipPathRef
) => {
  console.log("PROCESSING ACTION :: ", action);

  if (action.status == actionStatus.PROCESS) {
    switch (action.type) {
      case actionType.REPLACE_MEDIA:
        console.log("ACTION::REPLACING MEDIA");

        addOrReplaceVideo(
          action.target,
          action,
          sceneId,
          canvas,
          containerParams,
          videoRef,
          clipPathRef,
          false
        );

        updateTimelineInfo(timelineInfo, action.target, action.info);

        store.dispatch(
          updateLatestActionStatus({ status: actionStatus.COMPLETE })
        );

        break;
      case actionType.ADD_MEDIA:
        console.log("ACTION::ADDING MEDIA");

        addOrReplaceVideo(
          action.target,
          action,
          sceneId,
          canvas,
          containerParams,
          videoRef,
          clipPathRef,
          true
        );

        updateTimelineInfo(timelineInfo, action.target, action.info);

        store.dispatch(
          updateLatestActionStatus({ status: actionStatus.COMPLETE })
        );

        break;
      default:
        console.log(`ACTION TYPE INVALID`);
    }
  }
};

export { actionType, actionStatus, sendActionForProcessing };
