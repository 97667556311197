import React, {useState, useEffect, useCallback} from 'react'
import '../css/editor.css';
import {useDropzone} from 'react-dropzone'
import axios from 'axios'
import { uploadAssetToCloudAndSaveInDB } from '../services/firebaseStore';
import { useIsAuthenticated } from '../hooks/useIsAuthenticated';

const UploadBox = ({authUser, setLoading, setErrorDisplayed}) => {

    const [files, setFiles] = useState([])

    useEffect(() => {
        console.log('UPLOAD AUTH: ', authUser)
    }, [])

    const onDrop = useCallback(acceptedFiles => {
        if(authUser){
            console.log('Accepted Files: ', acceptedFiles)
            if(acceptedFiles?.length){
                console.log('User: ', authUser)
                // uploadAssetToCloudAndSaveInDB(authUser?.uid, acceptedFiles)
                uploadFiles(authUser?.uid, acceptedFiles)
            }
        }
      
    }, [authUser])


    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop, 
        accept: {
            'image/*': [],
            'video/*': [],
        },
        maxSize: 1024*1000*20
    })

    const uploadFiles = async (uid, acceptedFiles) => {
        console.log('Setting Loading => true')
        setLoading(true)
        const response = await uploadAssetToCloudAndSaveInDB(uid, acceptedFiles)
        const error = response.error;
        console.log('DATA RETURNED FROM UPLOAD API: ', response.result)
        console.log('Error: ', error)
        if(error){
            setErrorDisplayed(true)
        }
        console.log('Setting Loading => false')
        setLoading(false)
    }

    return (
        <div style={{width: '90%',   marginTop: 20, margin: 'auto',}}>
            {/* <h2 style={{color: '#6CB4EE'}}>Upload Files</h2> */}
            <div {...getRootProps({
                className: 'p-40 mt-10 border-dashed-2 upload-block'
            })}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <p>Click to browse, or ...</p> :
                    <div>
                        <h5 style={{ fontSize: 18, fontWeight: 600, textAlign: 'center'}}>Upload a file</h5>
                        <p style={{fontSize: 16, textAlign: 'center'}}>Click to browser, or drag and drop a file here</p>
                    </div>
                
            }
            </div>
                
        </div>
    )
}

export default UploadBox
