import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAspectRatio, setCaptionStyle } from '../redux/storySlice';
import '../css/editor.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getTextSizeFromAspectRatio } from '../constants/canvasSettings';
import { actionType, actionStatus } from '../utils/actionManager';

const AspectOptions = () => {
    const dispatch = useDispatch()
    
    const aspectRatio = useSelector(
        (state) => state.storyReducer.settings.aspectRatio
    )

    const actionLog = useSelector(
        (state) => state.storyReducer.actionLog
    )

    const captionStyle = useSelector(
        (state) => state.storyReducer.captionStyle
    )

    const updateAspect = (size) => {

        console.log('NEW FONT SIZE: ', getTextSizeFromAspectRatio(size))
        var style = {...captionStyle, fontSize: getTextSizeFromAspectRatio(size)}

        var actionLogRef = actionLog

        const action = {
            type: actionType.RESIZE,
            target: -1,
            oldState: aspectRatio,
            newState: size,
            status: actionStatus.COMPLETE
        }
        
        dispatch(setCaptionStyle({captionStyle: style}))
        // dispatch(
        //     updateActionLog({actionLog: [action, ...actionLogRef]})
        // )
        dispatch(
            setAspectRatio({aspectRatio: size})
        )
    }

    return (
        <>
            <div style={{fontSize: 18, fontWeight: 700, width: '95%', margin: 'auto', marginTop: 20, marginLeft: 20, color: '#424242'}}>Resize </div>
      
            <div style={{padding: 20, marginTop: 30}}>
                <div style={{width: '100%'}}>
                    <div className='aspect-ratio-option-container' onClick={() => updateAspect('16/9')}>
                        <div className={aspectRatio == '16/9' ? 'aspect-ratio-option-selected' : 'aspect-ratio-option'} style={{ width: 160, height: 90}}>
                            { aspectRatio == '16/9' ? <div style={{ position: 'absolute',top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}><CheckCircleIcon style={{color: '#20bf55', fontSize: 30}}/></div> : null}
                        </div>
                        <div style={{margin: 'auto', marginTop: 5, width: '100%', textAlign: 'center', fontSize: 14  }}>16:9 Landscape</div>
                        <div style={{margin: 'auto', width: '100%', textAlign: 'center', fontSize: 14  }}>For Youtube & Television</div>
                        
                    </div>
                    <div className='aspect-ratio-option-container' onClick={() => updateAspect('9/16')}>
                        <div className={aspectRatio == '9/16' ? 'aspect-ratio-option-selected' : 'aspect-ratio-option'} style={{ width: 56.25, height: 100}}>
                            { aspectRatio == '9/16' ? <div style={{ position: 'absolute',top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}><CheckCircleIcon style={{color: '#20bf55', fontSize: 30}}/></div> : null}

                        </div>
                        <div style={{margin: 'auto', marginTop: 5, width: '100%', textAlign: 'center', fontSize: 14  }}>9:16 Vertical</div>
                        <div style={{margin: 'auto', width: '100%', textAlign: 'center', fontSize: 14  }}>For Mobile & Social Media</div>

                    </div>
                    <div className='aspect-ratio-option-container-last' onClick={() => updateAspect('1/1')}>
                        <div className={aspectRatio == '1/1' ? 'aspect-ratio-option-selected' : 'aspect-ratio-option'} style={{ width: 90, height: 90}}>
                            { aspectRatio == '1/1' ? <div style={{ position: 'absolute',top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}><CheckCircleIcon style={{color: '#20bf55', fontSize: 30}}/></div> : null}

                        </div>
                        <div style={{margin: 'auto', marginTop: 5, width: '100%', textAlign: 'center', fontSize: 14  }}>1:1 Square</div>
                        <div style={{margin: 'auto', width: '100%', textAlign: 'center', fontSize: 14  }}>For Instagram & Social Media</div>

                    </div>
                    
                </div>
            </div>
        </>
    )
}

export default AspectOptions
