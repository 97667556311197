import React, {useState, useEffect} from 'react'
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material'
import Preview from './Preview';

const PreviewModalView = ({open, handleClose}) => {
    const [closeModal, setCloseModal] = useState(false)
    const [invokeCleanup, setInvokeCleanup] = useState(false)

    useEffect(() => {
        if(closeModal){
            console.log('CLOSING PREVIEW MODAL')
            handleClose()
        }
    }, [closeModal])

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='lg'
            >
                <DialogContent style={{position: 'relative'}}>
                    <div style={{}}>
                        <Preview setCloseModal={setCloseModal} invokeCleanup={invokeCleanup} />
                    </div>
                   
                    <div style={{position: 'absolute', right: 15, top: 15, fontSize: 25, cursor: 'pointer' }} onClick={() => setInvokeCleanup(true)}><i class="bi bi-x-lg"></i></div>
                </DialogContent>
        
            </Dialog>
    
        </>
    )
}

export default PreviewModalView
