import React from 'react';
import ReactLoading from 'react-loading';

const Loader = ({type, size}) => {

    const loaderContainer = {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    }

    const loaderContainerWrapper = {
        position: 'relative',
        width: '100%',
        height: '50%',

    }

    return (
        <div style={loaderContainerWrapper}>
            <div style={loaderContainer}>
                <ReactLoading type={type} color="#111827" height={size} width={size} />
            </div>
        </div>
        
    )
}
 
export default Loader