import React from 'react'
import { setMenuIndexSelected } from '../../redux/storySlice'
import { useDispatch, useSelector } from 'react-redux'
import '../../css/toolbar.css';
import { menuIndex } from '../../constants/editorConfig';

const CaptionEditOptions = () => {
    const dispatch = useDispatch()

    const openCaptionsMenu = () => {
        dispatch(setMenuIndexSelected({menuIndexSelected: menuIndex.CAPTIONS_MENU_INDEX}))
    }

    return (
        <div style={{width: '100%', marginTop: 5}}>
             <span className='edit-btn' onClick={openCaptionsMenu}>Edit Captions</span>
        </div>
    )
}

export default CaptionEditOptions
