import React, {useState, useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { setSettingsModalActive } from '../redux/storySlice'
import '../css/input.css'
import MenuBar from '../components/MenuBar';
import Sidebar from '../components/Sidebar';
import ProjectView from '../components/ProjectView';
import InputView from '../components/InputView';
import { useLocation } from "react-router-dom";

const HomePage = ({activeMenuIndex}) => {
    const dispatch = useDispatch()
    const { state } = useLocation();

    const [active, setActive] = useState(0);
    const [sizeFilterError, setSizeFilterError] = useState();

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        console.log('WINDOW SIZE: ', 720)
        if(windowSize.width < 720){
            setSizeFilterError('The tool is only available on desktop sizes for now.')
        }else{
            setSizeFilterError()
        }
    }, [windowSize])
    
    useEffect(() => {
       if(active){
           console.log('Active Menu Index: ', active)
           console.log('History Stack', window.history)
       }
    }, [active])

    useEffect(() => {
        if(activeMenuIndex){
            setActive(activeMenuIndex)
        }
    }, [activeMenuIndex])

    useEffect(() => {
        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        };
    
        window.addEventListener("resize", handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener("resize", handleResize);
        };
    }, []);

    const pageContent = () => {
        switch(active){
            case 0:
                window.history.pushState(null, "", "/home")
                return <InputView projectInfo={state?.data} />
            case 1:
                window.history.pushState(null, "", "/projects")
                return <ProjectView />
        }
    }



    // const openSettingsModal = () => {
    //     setSettingsModalActive(true)
    // }

    // const closeSettingsModal = () => {
    //     setSettingsModalActive(false)
    // }

    return (
        <>
            <MenuBar />
            {
                sizeFilterError ? 
                    <div style={{margin: 30, fontSize: 18, width: '100%', opacity: 0.8, fontStyle: 'italic', textAlign: 'center'}}>{sizeFilterError}</div>
                    :
                    <div style={{height: '92vh', overflow: 'scroll'}}>
                        <Sidebar active={active} setActive={setActive}/>
                        <div className='options-container'>
                            {pageContent()}
                        </div>
                    </div>
            }
           
           
          

        </>
    )
}

export default HomePage
