import React from 'react'
import '../../css/toolbar.css';
import { menuIndex } from '../../constants/editorConfig';
import { setMenuIndexSelected } from '../../redux/storySlice'
import { useDispatch, useSelector } from 'react-redux'

const MediaEditOptions = () => {

    const dispatch = useDispatch()

    const openMediaMenu = () => {
        dispatch(setMenuIndexSelected({menuIndexSelected: menuIndex.MEDIA_MENU_INDEX}))
    }

    return (
        <div style={{width: '100%', marginTop: 5}}>
            <span className='edit-btn' onClick={openMediaMenu}>Replace Media</span>
        </div>
    )
}

export default MediaEditOptions
